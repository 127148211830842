import Button from 'components/utils/Button';
import { Link } from 'components/utils/Link';

export default function Error404() {
  return (
    <div className="wrapper503 d-f fd-c">
      <div className="d-f fd-c jc-c ai-c wrapper503-text">
        <div className="relative ta-c">
          <div className="absolute">404</div>
          <h2>{"That page can't be found"}</h2>
          <p>Sorry, we couldn't find the page you’re looking for.</p>
        </div>
        <div className="button-wrapper d-f ai-c jc-c">
          <Link href="/" passHref>
            <Button className={'primary large'} label={'Back to homepage'} />
          </Link>
        </div>
      </div>
      <style jsx>
        {`
          .logo {
            max-width: 380px;
          }
          .logo img {
            margin: auto;
          }
          .button-wrapper {
            z-index: 2;
            margin-top: 30px;
          }
          .wrapper503 h2,
          .wrapper503 p {
            text-align: center;
            margin: auto;
          }
          .wrapper503 p {
            font-size: 20px;
            color: var(--grey-light);
            font-weight: 300 !important;
          }
          .wrapper503 h6 {
            font-size: 16px;
            margin-top: 2px;
          }
          .wrapper503 {
            height: 100vh;
          }
          .wrapper503-text {
            max-width: 650px;
            margin: auto;
            padding: 1em;
          }
          .absolute {
            font-size: 140px;
            text-align: center;
            font-weight: 800;
            opacity: 1;
            color: var(--primary);
            /* color: var(--grey-dark); */
            /* background: linear-gradient(270deg, #0243c6 -3.3%, #005eff 98.41%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; */
          }
        `}
      </style>
    </div>
  );
}
